@import main, font, basic, layout, highlight, classes, album-wall

@media (max-width: 1100px)
  .archive-list
    width: 100%

@media (max-width: 550px)
  .archive-date
    display: none
  nav
    width: 100%
    display: flex
    margin: 0 5px
    justify-content: space-between
    flex-direction: row
    flex-wrap: wrap


@media (min-width: 32em)
  body > header, body > article, body > footer
    padding: 1.5em calc(38% - 12em)

@media only screen and (max-width: 400px)
  .band-logo
    max-width: 60px

@media only screen and (max-width: 600px)
  .band-logo
    max-width: 130px
  nav
    text-align: center

@media only screen and (max-width: 80px)
  .band-logo
    max-width: 200px

