body > header, body > footer
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  align-items: center
  padding: 0 0

article header
  margin-bottom: 1.5em

article header h1
  font-size: 1.8em
  margin: 0 0 .1em

nav
  margin: .4em -.8em

nav a
  margin: .4em .8em

tab
  margin-left: 1rem
