
.album-wall
  display: flex
  flex-wrap: wrap
  justify-content: center
  margin-top: 1em

.album-display
  display: inline-block
  justify-content: center
  margin: 0
  padding: 0
  max-width: 200px
  max-height: 200px
  width: 200px
  height: 200px
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  position: relative

  a, span
    display: none
    margin: auto
    color: white
    font-size: 1em
    text-align: center
    text-shadow: 3px 3px 4px black
    position: absolute
    width: 100%

  a
    bottom: 20%

  span
    top: 20%

.album-display:hover
  img
    filter: blur(5px) brightness(0.75)
    transition: filter 0.5s

  a, span
    display: block
    transition: opacity 0.5s
    opacity: 0.8
    z-index: 9999

