
html
  font-size: $font-size
  background: $dark
  color: #FAFAFA

body
  font-family: "PT Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Roboto", sans-serif
  font-weight: $font-weight
  line-height: $line-height
  margin: 0
  -webkit-text-size-adjust: 100%
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

h1, h2, h3, h4, h5, h6
  font-weight: $heading-weight
  line-height: 115%

h1
  font-size: 1.8em

h2
  font-size: 1.4em

h3
  font-size: 1.2em

h4
  font-size: 1.0em

b, strong, th
  font-weight: $font-weight + 200

a
  color: $link-color
  text-decoration: inherit
  &:hover
    text-decoration: underline

body > header, body > article, body > footer
  padding: 1.5em

header a, footer a
  color: inherit

header time
  color: #b2b2b3

body > header
  border-bottom: 3px solid rgba($light, 0.05)

hr
  border: 1px solid reduce(50)
  margin: 2em 0


article:not(:last-child)
  border-bottom: 2px solid reduce(20)

blockquote
  background: reduce(15)
  border-left: 3px solid reduce(90)
  padding: 1px 1.5em
  opacity: .9

blockquote p
  line-height: 130%

blockquote, figure
  margin: 1em 0

img
  display: block
  margin-left: auto
  margin-right: auto

ul, li
   margin: .2em 0

img
  border-radius: 2px
  max-width: 100%
  height: auto

table
  width: 100%
  border-collapse: collapse

th, td
  padding: .5em 1em
  line-height: 105%

th
  font-size: 125%

tr
  border: 1px solid reduce(20)

tr:nth-child(odd) td //tr:hover
  background: reduce(8)

pre
  background: reduce(15)
  border-radius: 2px
  line-height: 100%
  font-size: .8em
  margin: 1.5em 0
  padding: .8em 1.2em
  overflow-x: auto
  opacity: .9

\:not(pre) > code
  font-size: .9em
  background: #000000
  border-radius: 2px
  margin: .3em .1em
  padding: 0 .4em

kbd
  margin: 0 0.5em
  padding: 0 0.5em
  border-radius: 3px
  border: 1px solid rgb(204, 204, 204)
  color: rgb(51, 51, 51)
  font-size: 13px
  display: inline-block
  box-shadow: 0px 1px 0px rgba(0,0,0,0.2), inset 0px 0px 0px 2px #ffffff
  background-color: rgb(247, 247, 247)
  -moz-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #ffffff inset
  -webkit-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #ffffff inset
  -moz-border-radius: 3px
  -webkit-border-radius: 3px
  text-shadow: 0 1px 0 #fff

video
  width: 100%

