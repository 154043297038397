@use "sass:color"

.categories
  color: #68f
  margin-bottom: .15em

.logo
  margin: 0 auto 2em auto

  img
    height: auto
    width: 100%
    max-width: 6em
    max-height: 6em

.hfill
  display: flex
  justify-content: space-between
  list-style-type: none
  padding-bottom: 0
  padding-left: 0
  margin: auto
  width: 90%

.archive-list
  margin: auto

  ul
    list-style-type: none
    width: 90%
    margin: auto
    padding: 0
    &:hover
      background-color: color.adjust($dark, $lightness: -4%)
      transition: 0.2s ease-in-out

    li:nth-child(even)
      white-space: nowrap


  li
    padding: 0 6px

.archive-date
  display: inline-block


.hidden
  display: none


.icons
  background-color: #000
  box-shadow: 0 0.125rem 1rem rgba(27, 40, 50, 0.04), 0 0.125rem 2rem rgba(27, 40, 50, 0.08), 0 0 0 0.0625rem rgba(27, 40, 50, 0.024)
  a:hover svg
    color: reduce(100)

  svg
    height: 1em
    width: 1em
    fill: currentColor
    transition: .2s color
    vertical-align: middle
    margin-bottom: .15em


//.more
//  margin: 2em 0 1em
//
//.more a
//  border-radius: 2px
//  border: 1.5px solid $link-color
//  padding: .4em .8em
//  transition: .2s
//
//.table-img
//  width: 150
//  height: auto
//  align: center
//
//.more a:hover
//  color: #fff
//  background: $link-color
//  text-decoration: inherit

.archive
  font-size: 1.1em

.archive time
  display: inline-block
  min-width: 8ch
  margin: 0 .2em

.related span
  display: block
  color: reduce(100)

.related a
  font-size: 1.2em
  font-weight: bold

.next
  text-align: right

.title
  font-size: 1.5em
  width: 100%
  margin: .1em 0 .6em

.small img
  max-height: 200px
  margin: 10px

.big-button
  -webkit-appearance: button
  -moz-appearance: button
  appearance: button

  //background: url("/assets/meta/reddit-icon.png") no-repeat left center
  background-position: 10px 20px
  background-size: 10%
  padding: 20px 64px
  background-color: #17181c
  text-align: right
  display: inline-block
  border: solid white
  width: fit-content
  margin: 1em auto
  display: table
  font-size: 150%
  color: white

.big-button:hover
  font-weight: bold
  text-decoration: none

.photogrid
  margin: 0 auto
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -ms-flex-wrap: wrap
  flex-wrap: wrap
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center

.wrapper
  padding: 1rem 0
  background: rgba(109, 109, 112, 0.15)
  color: #e4e4e8
  text-align: center

.photogrid-item figure
  background: #17181c
  padding: 10px
  margin: 10px
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1)

.photogrid-item figure img
  display: block
  max-width: 200px
  heigth: auto

.photogrid-item figcaption
  display: block
  padding: 15px
  background: #474952

.project-card
  margin: 1em 0
  padding: 15px
  background-color: #000000

  img
    border-radius: 5px
    text-align: center
    display: block
    margin-left: auto
    margin-right: auto

  *
    margin: 5px

  ul
    margin: 0

.definition-block, .proof-block, .theorem-block, .lemma-block
  background-color: #131317
  border-style: solid
  border-width: 0.5px
  border-color: #BDBDBD
  padding: 0 10px
  margin: 20px 0

  h2,h3,h4,h5,h6
    margin-top: 7px
    margin-bottom: 10px


.definition-label, .proof-label, .theorem-label, .lemma-label
  width: 100%
  height: 16px
  font-size: 15px
  margin: 0 -10px
  padding: 5px 10px
  vertical-align: middle
  line-height: 100%


.definition-label
  background-color: #6b0000

.proof-label
  background-color: #00188F

.theorem-label
  background-color: #710793

.lemma.label
  background-color: #007000

.edit-button
  margin-left: 1em

.bands-table
  overflow-x: auto
  width: 100%
  max-width: 100%

.band-logo
  max-width: 400px
  max-height: 140px
  width: auto
  height: 100%
  margin: 0px auto
  display: block

.video-display
  width: 100%
  padding: 5px
  text-align: right
  margin: 10px auto

  i
    font-size: 1em
    margin-right: 10px

.code-no-err
  .err
    all: revert

.code-display
  display: block
  white-space: nowrap

.signature
  padding-right: 60px
  text-align: right
  font-weight: $heading-weight
  line-height: 115%


.image-w300px
  width: 300px
  max-width: 100%
  height: auto
  margin: auto

  img
    width: 300px
    height: auto

.image-w500px
  width: 500px
  max-width: 100%
  height: auto
  margin: auto

  img
    width: 500px
    height: auto

.spliter
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  align-items: stretch
  margin-top: 1em
  width: 100%

.spliter-item
  display: inline-block
  margin: 0
  padding: 0
  width: 50%

//.spliter-item:has(> .video-display)
//  min-width: 300px
//  width: 100%

.webring-display
  .spliter
    .spliter-item
      min-width: 300px

