@use "sass:color"

$font-size: calc(0.8rem + 0.3vw) !default
$font-weight: 400 !default
$heading-weight: 600 !default
$line-height: 1.3 !default
$link-color: #708bff !default // #68f
$dark: #17181c !default // #1c1d22
$light: #BDBDBD


@function reduce ($percent)
  @return rgba(color.mix($dark, $light), calc($percent / 100))
