@media (min-width: 50em)
  @font-face
    font-family: 'PT Sans'
    src: local('PT Sans'), local('PTSans-Regular'), url('../fonts/PTSans-Regular.woff') format('woff')
    font-weight: normal
    font-style: normal

  @font-face
    font-family: 'PT Sans'
    src: local('PT Sans Bold'), local('PTSans-Bold'), url('../fonts/PTSans-Bold.woff') format('woff')
    font-weight: bold
    font-style: normal
